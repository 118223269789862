import React, { useEffect, useState} from 'react';
import { useLocation } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Tabbar } from "../compornents/Tabbar";
import { Post } from '../compornents/Post';

// Leafletのアイコンを修正する
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// 地図の中心位置を更新するためのカスタムフック
const UpdateMapCenter = ({ position }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(position);
  }, [position, map]);
  return null;
};

const fetchLocation = async (setPosition,order_id) => {
  try {
    const request_data = {order_id:order_id}
    const response = await Post('post', '/order/location.php', request_data);
    // console.log(response);
    // console.log([response.location_lat, response.location_lng]);
    setPosition([response.location_lat, response.location_lng]);
  } catch (error) {
    console.error('位置情報の取得に失敗しました', error);
  }
};

export const RealTimeMap = () => {
  let location = useLocation();
  const order = location.state.order;
  const [position, setPosition] = useState([51.505, -0.09]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('interval');
      fetchLocation(setPosition,order.id);
    }, 20000);

    return () => clearInterval(interval);
  }, [order]);

  return (
    <div className="App">
      <div className="RealTimeMap">
        <MapContainer center={position} zoom={13} style={{ height: "50vh", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            <Popup>
              配送スタッフの位置
            </Popup>
          </Marker>
          <UpdateMapCenter position={position} />
        </MapContainer>
        <Tabbar />
      </div>
    </div>
  );
}

