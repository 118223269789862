import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Analytics } from '../compornents/Analytics';
import { Post } from '../compornents/Post';
import { toast } from 'react-toastify';
import { RiTruckLine } from "react-icons/ri";
import { useModal } from 'react-hooks-use-modal';
import { TailSpin } from 'react-loading-icons';

export const Cleaning = () => {
  // {order_id: "1234"}
  const navigate = useNavigate();
  // 戻る
  const back = () => {
    navigate(-1);
  };
  // 初期化
  const [data, setData] = useState(null); // 初期値はnullである必要あり
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });
  useEffect(() => {
    let unmounted = false;
    // 注文情報取得
    (async () => {
      openLoadingModal();
      const request_data = {};
      const response_data = await Post('post', '/company/index.php', request_data);
      console.log(response_data);
      if (response_data.result === 1) {
        if(!unmounted){
          setData(response_data);
        }
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    })();
    //クリーンアップ関数を返す

    return () => { unmounted = RiTruckLine};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      <div className="Cleaning">
        <Analytics title="提携クリーニング店" />
        <div className="toolbar mb-3">
          <div className="toolbar_left div_btn" onClick={back} >
            <BsChevronLeft />
          </div>
          <div className="toolbar_center">
            提携クリーニング店
          </div>
          <div className="toolbar_right">
          </div>
        </div>
        <div className="m-4">
          <div className="mb-3">
            <div className="h5 mb-2">
              店名
            </div>
            <div className="">
              {data?.gcompany.name}
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              所在地
            </div>
            <div className="">
              〒{data?.gcompany.zip}
            </div>
            <div className="">
              {data?.gcompany.pref}{data?.gcompany.add1}{data?.gcompany.add2}{data?.gcompany.add3}{data?.gcompany.add4}
            </div>
          </div>
          <div className="mb-3">
            <div className="h5 mb-2">
              電話受付時間
            </div>
            <div className="">
              {data?.gcompany.contact}
            </div>
          </div>
          {data && (
            <img src={data?.gcompany.img} className="w-100" alt="cleaning" />
          )}
        </div>
        <Tabbar menu="account" />
      </div>

      <LoadingModal>
        <div className="modal rounded text-center pb-4 pl-4 pr-4">
          <button onClick={closeLoadingModal} className="hide">閉じる</button>
          <div >
            <TailSpin stroke="#000" />
          </div>
          <div className="">
            <div className="">
              処理中です
            </div>
          </div>
        </div>
      </LoadingModal>
    </div>
  );
}
