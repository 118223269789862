import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoHistory } from "react-icons/go";
import { MdOutlineHome } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { Post } from '../compornents/Post';

export const Tabbar = ({menu}) => {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState([]);
    // メニュー選択
    const selectMenu = (menu) => {
        // console.log(page);
        navigate("/" + menu);
    };
    useEffect(() => {
        let unmounted = false;
        // 商品情報取得
        (async () => {
            const request_data = {};
            const response_data = await Post('post', '/customer/index.php', request_data);
            // console.log(response_data);
            if (response_data.result === 1) {
                const customer = response_data.customer;
                if (!unmounted) setCustomer(customer);
            } else {
                // エラー

            }
        })();
        //クリーンアップ関数を返す
        return () => { unmounted = true; };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="tabbar">
            <div className="menu_list">
                <div className={menu === 'home' ? ("menu active  text-center") : ("menu text-center")} onClick={() => selectMenu('home')}>
                    <div className="h3 menu_icon">
                        <MdOutlineHome />
                    </div>
                    <div className="">
                        <small>ホーム</small>
                    </div>
                </div>
                <div className={menu === 'orders' ? ("menu active  text-center") : ("menu text-center")} onClick={() => selectMenu('orders')}>
                    {Number(customer.new_comment_qty) !== 0 && (
                        <div className="float-right badge badge-danger" style={{ position: 'absolute', top: '-0.6rem', left: '53%' }}>{customer.new_comment_qty}</div>
                    )}
                    <div className="h4 menu_icon2">
                        <GoHistory />
                    </div>
                    <div className="menu_orders">
                        <small>注文履歴</small>
                    </div>
                </div>
                <div className={menu === 'account' ? ("menu active  text-center") : ("menu text-center")} onClick={() => selectMenu('account')}>
                    <div className="h3 menu_icon">
                        <BiUser />
                    </div>
                    <div className="">
                        <small>アカウント</small>
                    </div>

                </div>
            </div>
        </div>
    )
}
