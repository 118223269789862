import React from "react";
import { useStyletron } from "baseui";
import { PinCode } from "baseui/pin-code";

export const RegisterPinCode = () => {
  const [css, theme] = useStyletron();
  const [values, setValues] = React.useState(["", "", "", ""]);
  return (
    <div className={css({ display: "flex" })}>
      <div className={css({ marginLeft: theme.sizing.scale300 })} />
        <PinCode
          values={values}
          onChange={({ values }) => {
            setValues(values);
          }}
          autoFocus
        />
    </div>
  );
}
