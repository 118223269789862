/* eslint-disable no-unused-vars */
import { useRef,useState, useEffect, } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Tabbar } from "../compornents/Tabbar";
import { Analytics } from '../compornents/Analytics';
import { useModal } from 'react-hooks-use-modal';
import { Post, PostFile } from '../compornents/Post';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loading-icons';
import { Tabs, Tab } from "baseui/tabs";
import { Client as Styletron } from 'styletron-engine-monolithic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { Textarea } from "baseui/textarea";

const engine = new Styletron();

export const Order = () => {
  // order_id取得
  let location = useLocation();
  const params = useParams();
  const order_id = params.order_id;
  // console.log(location.state);
  const [order, setOrder] = useState(location.state === null?[]:location.state.order);
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [activeKey, setActiveKey] = useState("0");
  const navigate = useNavigate();
  // ファイル入力のリファレンスを作成
  const fileInputRef = useRef(null);
  // 戻る
  const back = () => {
    navigate(-1);
  };

  // モーダル
  const [LoadingModal, openLoadingModal, closeLoadingModal] = useModal('root', {
    preventScroll: true //これはオプション。デフォルトはfalse
  });

  const requestMessage = async () => {
    openLoadingModal();

    const request_data = {
      'order_id': order_id,
      'message': message,
    };
    const response_data = await Post('post', '/order/create_comment.php', request_data);
    if (response_data.result === 1) {
      setComments(response_data.comments);
      setMessage('');
    } else {
      // エラー
      toast(response_data.error_message);
    }

    closeLoadingModal();
  };


  const requestImage = async () => {
    openLoadingModal();


    const request_data = {
      order_id: order_id,
    }
    const response_data = await PostFile('post', '/order/create_comment_image.php', request_data,file);

    if (response_data.result === 1) {
      setComments(response_data.comments);
      setFile(null);
      // ファイル入力の値をクリア
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      // エラー
      toast(response_data.error_message);
    }
    closeLoadingModal();
  };



  useEffect(() => {
    // 注文情報取得
    async function getOrder() {
      openLoadingModal();
      const request_data = {
        'order_id' : order_id,
      }
      // post
      const response_data = await Post('post', '/order/detail.php', request_data);
      // 結果
      // console.log(response_data);
      if (response_data.result === 1) {
        setOrder(response_data.order);
        setComments(response_data.comments);
      } else {
        // エラー
        toast(response_data.error_message);
      }
      closeLoadingModal();
    }
    getOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <div className="App">
          <div className="Order">
            <Analytics title="以前の注文詳細" />
            <div className="toolbar">
              <div className="toolbar_left div_btn" onClick={back} >
                <BsChevronLeft />
              </div>
              <div className="toolbar_center">
                以前の注文
              </div>
              <div className="toolbar_right">
              </div>
            </div>
            <div className="mt-3 mb-5">
              <div className="order_body mb-3">
                <div className="order_header mb-2">
                  <div className={Number(order.otype) === 8 ? "order_status" : "order_status text-blue"} >
                    {order.status_name}
                  </div>
                  <div className="order_date">
                    ・{order.order_date}
                  </div>
                </div>
                <div className="h4 mb-2">
                  ご注文内容
                </div>
                <div className="content mb-4">
                  <div className="row mb-2 border-bottom">
                    <div className="col-8">
                      {order.item_name}
                    </div>
                    <div className="col-4 mb-2 text-right">
                      ￥{Number(order.kin).toLocaleString()}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-8">
                      小計
                    </div>
                    <div className="col-4 text-right">
                      ￥{Number(order.kin).toLocaleString()}
                    </div>
                  </div>
                  <div className="row mb-2 border-bottom">
                    <div className="col-8">
                      Uber配達手数料
                    </div>
                    <div className="col-4 mb-2   text-right">
                      ￥{Number(order.uber_fee).toLocaleString()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 h5">
                      合計
                    </div>
                    <div className="col-6 text-right h4">
                      ￥{Number(order.goukei).toLocaleString()}
                    </div>
                  </div>
                </div>
                <div className="h6 mb-1">お受渡し場所</div>
                <div className="mb-3">
                  {order.pref + order.add1 + order.add2}<br />
                  {order.add3}{order.room}
                </div>
                <div className="h4 mb-2">
                  コメント
                </div>
                <div className="border">

                  <Tabs
                    onChange={({ activeKey }) => {
                      setActiveKey(activeKey);
                    }}
                    activeKey={activeKey}
                  >
                    <Tab title="テキスト">
                      <div>
                        <Textarea
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                          placeholder="例:コメントを入力してください"
                          clearOnEscape
                          className=""
                        />
                        <button className="btn btn-oval btn-yellow form-control mt-2" onClick={requestMessage}>送信</button>
                      </div>
                    </Tab>
                    <Tab title="画像">
                      <div>
                        <label>
                          <input type="file" accept="image/*" onChange={e => (setFile(e.target.files[0]))} ref={fileInputRef} className="file-button" />
                        </label>
                        <button className="btn-oval btn-yellow form-control mt-2" onClick={requestImage}>送信</button>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                <hr />
                <div className="mt-3">
                  {comments.length > 0 ? comments.map(comment =>
                    <div key={comment.id}>
                      {Number(comment.type) === 0 ? (
                        <div className="">
                          <div>{comment.staff_name}：
                            {Number(comment.read_suu) === 0 && (
                              <span className="text-danger">New!</span>
                            )}
                          </div>
                          <div style={{ whiteSpace :'pre'}}>
                            {comment.message}
                          </div>
                          <div className="text-right w-100">
                            <small className="text-gray-dark">{comment.insert_name}</small>
                          </div>
                        </div>
                      ) : Number(comment.type) === 1 ? (
                        <div className="mb-3 ">
                          <div>{order.name}：</div>
                          <div style={{ whiteSpace: 'pre' }}>
                            {comment.message}
                          </div>
                          <div className="text-right w-100">
                            <small className="text-gray-dark">{comment.insert_name}</small>
                          </div>
                        </div>
                        ) : Number(comment.type) === 2 ? (
                          <div className="">
                            <div>{comment.staff_name}：</div>
                            <div className="mr-5 ml-5 mb-3 mt-2">
                              <img src={comment.message} className="border CommentImage w-100" alt="Comment" />
                            </div>
                            <div className="text-right w-100">
                              <small className="text-gray-dark">{comment.insert_name}</small>
                            </div>
                          </div>
                      ) : Number(comment.type) === 3 && (
                        <div className="">
                          <div>{order.name}：</div>
                          <div className="mr-5 ml-5 mb-3 mt-2">
                            <img src={comment.message} className="border CommentImage w-100" alt="Comment" />
                          </div>
                          <div className="text-right w-100">
                            <small className="text-gray-dark">{comment.insert_name}</small>
                          </div>
                        </div>
                      )}
                      <hr />
                    </div >
                  ) : (<div className="text-center">コメントはありません</div>)}
                </div>
              </div>
            </div>
            <LoadingModal>
              <div className="modal rounded text-center pb-4 pl-4 pr-4">
                <button onClick={closeLoadingModal} className="hide">閉じる</button>
                <div >
                  <TailSpin stroke="#000" />
                </div>
                <div className="">
                  <div className="">
                    処理中です
                  </div>
                </div>
              </div>
            </LoadingModal>
            <Tabbar menu="orders" />
          </div>

        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}
